import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "config";

const VideoKYCPage = () => {
  const navigate = useNavigate();
  const leadId = sessionStorage.getItem("Lead_Id");

  const handleInitiate = async () => {
    if (!leadId) {
      console.error("Lead_Id not found in sessionStorage.");
      return;
    }

    try {
      // Generate a unique token (you can use a library like UUID or your own method)
      const token = generateToken();

      // Generate the resumption link with the token as a query parameter
      const resumeLink = `${window.location.origin}/proceed?token=${token}&id=${leadId}`;
      // API call for initiating KYC
      await axios.post(`${config.apiBaseUrl}/api/set-online`, {
        lead_id: leadId,
        token: token, // send the token to the server
        link: resumeLink, // send the link to the server
      });
      console.log("Initiate KYC API call successful.");

      // Navigate to initiate page
      navigate("/initiate-kyc");
    } catch (error) {
      console.error("Error in initiate KYC API call:", error);
    }
  };

  // Function to generate a unique token (you can use a library like uuid for better token generation)
  const generateToken = () => {
    return Math.random().toString(36).substring(2, 10); // simple random token for demo purposes
  };

  const handleSchedule = async () => {
    if (!leadId) {
      console.error("Lead_Id not found in sessionStorage.");
      return;
    }

    try {
      // API call for scheduling KYC
      await axios.post(`${config.apiBaseUrl}/api/set-online`, {
        lead_id: leadId,
      });
      console.log("Schedule KYC API call successful.");

      // Navigate to schedule page
      navigate("/schedule-kyc");
    } catch (error) {
      console.error("Error in schedule KYC API call:", error);
    }
  };

  // Prevent going back or closing the page
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // For Chrome to trigger confirmation
      return ""; // For other browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4">
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-6 border border-gray-300 transform transition-all ease-in-out duration-300">
        <div className="text-center">
          <h2 className="text-2xl font-semibold mb-6 text-gray-900">
            Proceed to Video KYC
          </h2>
          <div className="flex flex-col md:flex-row justify-center gap-4 mb-6">
            <button
              onClick={handleInitiate}
              className="w-full md:w-auto py-2 px-4 bg-indigo-600 text-white rounded-md shadow hover:bg-indigo-700 focus:outline-none transition-transform transform hover:scale-105"
            >
              Initiate Now
            </button>
            <button
              disabled
              onClick={handleSchedule}
              className="w-full md:w-auto py-2 px-4 bg-gray-600 text-white rounded-md shadow hover:bg-gray-700 focus:outline-none transition-transform transform hover:scale-105"
            >
              Schedule Later
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoKYCPage;
