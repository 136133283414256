import React, { useState } from 'react';

const VideoMeeting = () => {
  const [roomName, setRoomName] = useState('unique-room-name'); // Dynamically generate this if needed
  const [userName, setUserName] = useState('User123'); // Dynamically set this based on the user

  const handleConnectClick = () => {
    startMeeting();
  };

  const startMeeting = () => {
    const options = {
      roomName: roomName,
      width: '100%',
      height: '100%',
      parentNode: document.getElementById('meet'),
      userInfo: {
        displayName: userName,
      },
      configOverwrite: {
        startWithAudioMuted: false,
        startWithVideoMuted: false,
      },
    };

    // Correcting the Jitsi Meet API URL (make sure the base URL is correct)
    const api = new window.JitsiMeetExternalAPI('https://meet.jit.si', options);
    
    api.addEventListener('videoConferenceLeft', () => {
      console.log('User left the conference');
    });
  };

  return (
    <div>
      <h1>Video KYC</h1>
      <div>
        <button onClick={handleConnectClick}>Connect</button>
      </div>
      <div id="meet" style={{ width: '100%', height: '500px' }}></div>
    </div>
  );
};

export default VideoMeeting;

