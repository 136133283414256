import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "config";
import Popup from "./popup";

const FormComponent = () => {
  const initialFormData = {
    first_name: "",
    last_name: "",
    email: "",
    contact: "",
    father_spouse_name: "",
    gender: "",
    marital_status: "",
    dob: "",
    nationality: "",
    current_address: "",
    permanent_address: "",
    customer_photo: "",
    pan_photo: "",
    signature_photo: "",
    aadhaar_photo: "",
  };

  const [countries, setCountries] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState(""); // 'success' or 'error'
  const [formData, setFormData] = useState(initialFormData);

  const navigate = useNavigate();

  // Create refs for file inputs
  const aadhaarPhotoRef = useRef(null);
  const panPhotoRef = useRef(null);
  const customerPhotoRef = useRef(null);
  const signaturePhotoRef = useRef(null);

  // Fetching countries for the nationality dropdown
  useEffect(() => {
    const fetchCountries = async () => {
      const res = await fetch("https://restcountries.com/v3.1/all");
      const data = await res.json();
      setCountries(data);
    };

    fetchCountries();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg"]; // Allowed mime types
      if (!allowedTypes.includes(file.type)) {
        setPopupMessage(
          "File type is not allowed. Only JPG and JPEG are allowed."
        );
        setPopupType("error");
        setShowPopup(true);
        return; // Stop the file from being set if it's invalid
      }

      // Instead of creating a file path, store the file directly
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a new FormData instance to handle both text and file data
    const formDataToSend = new FormData();

    // Append form data fields
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });

    try {
      // Use the formData object directly here to send data
      const response = await axios.post(
        `${config.apiBaseUrl}/api/customer-kyc-details`,
        formDataToSend
      );

      // Check if submission is successful
      if (response.status === 201) {
        setPopupMessage("Data Submitted Successfully!");
        setPopupType("success");
        setShowPopup(true);
        setFormData(initialFormData);
        // Reset file inputs after submission
        resetFileInputs();

        sessionStorage.setItem("Lead_Id", response.data.lead_id);
        const LeadId = sessionStorage.getItem("Lead_Id");
        console.log("Lead Id:", LeadId);
      } else {
        // Use the API response error message if available
        setPopupMessage(response.data.message);
        setPopupType("error");
        setShowPopup(true);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Data submission failed.";
      setPopupMessage(errorMessage);
      setPopupType("error");
      setShowPopup(true);
    }
  };

  const resetFileInputs = () => {
    // Reset file inputs using refs
    if (aadhaarPhotoRef.current) aadhaarPhotoRef.current.value = "";
    if (panPhotoRef.current) panPhotoRef.current.value = "";
    if (customerPhotoRef.current) customerPhotoRef.current.value = "";
    if (signaturePhotoRef.current) signaturePhotoRef.current.value = "";
  };

  const onPopUpClose = () => {
    setShowPopup(false);
    if (popupType === "success") {
      // Redirect to Video KYC page after successful submission
      setTimeout(() => {
        navigate("/proceed");
      }, 1500); // Wait a little bit for the popup to show
    }
    resetFileInputs();
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white shadow-xl rounded-lg my-7 border-2 border-gray-300">
      <h3 className="text-center text-xl font-semibold pb-5">
        Fill the Form Details For V-KYC Verification.
      </h3>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* First Name */}
          <div>
            <label
              htmlFor="first_name"
              className="block text-sm font-medium text-gray-700"
            >
              First Name
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter First Name"
              required
              autoComplete="off"
            />
          </div>

          {/* Last Name */}
          <div>
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter Last Name"
              required
              autoComplete="off"
            />
          </div>

          {/* Email (Full Width) */}
          <div className="md:col-span-2">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter Email"
              required
              autoComplete="off"
            />
          </div>

          {/* Contact */}
          <div>
            <label
              htmlFor="contact"
              className="block text-sm font-medium text-gray-700"
            >
              Mobile
            </label>
            <input
              type="text"
              id="contact"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter Mobile No."
              required
              autoComplete="off"
            />
          </div>

          {/* Father/Spouse Name */}
          <div>
            <label
              htmlFor="father_spouse_name"
              className="block text-sm font-medium text-gray-700"
            >
              Father/Spouse Name
            </label>
            <input
              type="text"
              id="father_spouse_name"
              name="father_spouse_name"
              value={formData.father_spouse_name}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter Father/Spouse Name"
              required
              autoComplete="off"
            />
          </div>

          {/* Gender */}
          <div>
            <label
              htmlFor="gender"
              className="block text-sm font-medium text-gray-700"
            >
              Gender
            </label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {/* Marital Status */}
          <div>
            <label
              htmlFor="marital_status"
              className="block text-sm font-medium text-gray-700"
            >
              Marital Status
            </label>
            <select
              id="marital_status"
              name="marital_status"
              value={formData.marital_status}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Select Marital Status</option>
              <option value="Married">Married</option>
              <option value="Unmarried">Unmarried</option>
            </select>
          </div>

          {/* Date of Birth */}
          <div>
            <label
              htmlFor="dob"
              className="block text-sm font-medium text-gray-700"
            >
              Date of Birth
            </label>
            <input
              type="date"
              id="dob"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          {/* Nationality */}
          <div>
            <label
              htmlFor="nationality"
              className="block text-sm font-medium text-gray-700"
            >
              Nationality
            </label>
            <select
              id="nationality"
              name="nationality"
              value={formData.nationality}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Select Nationality</option>
              {countries.map((country) => (
                <option key={country.cca3} value={country.name.common}>
                  {country.name.common}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Current Address (Full Width) */}
          <div className="md:col-span-2">
            <label
              htmlFor="current_address"
              className="block text-sm font-medium text-gray-700"
            >
              Current Address
            </label>
            <textarea
              id="current_address"
              name="current_address"
              value={formData.current_address}
              onChange={handleChange}
              autoComplete="off"
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          {/* Permanent Address (Full Width) */}
          <div className="md:col-span-2">
            <label
              htmlFor="permanent_address"
              className="block text-sm font-medium text-gray-700"
            >
              Permanent Address
            </label>
            <textarea
              id="permanent_address"
              name="permanent_address"
              value={formData.permanent_address}
              onChange={handleChange}
              autoComplete="off"
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Aadhaar Card */}
          <div>
            <label
              htmlFor="aadhaar_photo"
              className="block text-sm font-medium text-gray-700"
            >
              Aadhaar Card
            </label>
            <input
              type="file"
              id="aadhaar_photo"
              name="aadhaar_photo"
              onChange={handleFileChange}
              ref={aadhaarPhotoRef}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          {/* Pan Card */}
          <div>
            <label
              htmlFor="pan_photo"
              className="block text-sm font-medium text-gray-700"
            >
              Pan Card
            </label>
            <input
              type="file"
              id="pan_photo"
              name="pan_photo"
              onChange={handleFileChange}
              required
              ref={panPhotoRef}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          {/* Customer Photo */}
          <div>
            <label
              htmlFor="customer_photo"
              className="block text-sm font-medium text-gray-700"
            >
              Customer Photo
            </label>
            <input
              type="file"
              id="customer_photo"
              name="customer_photo"
              onChange={handleFileChange}
              ref={customerPhotoRef}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          {/* Signature Photo */}
          <div>
            <label
              htmlFor="signature_photo"
              className="block text-sm font-medium text-gray-700"
            >
              Signature Photo
            </label>
            <input
              type="file"
              id="signature_photo"
              name="signature_photo"
              onChange={handleFileChange}
              ref={signaturePhotoRef}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-6">
          <button
            type="submit"
            className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Submit
          </button>
        </div>
      </form>

      {/* Show popup if there is a message */}
      {showPopup && (
        <Popup
          message={popupMessage}
          type={popupType}
          onClose={() => onPopUpClose()}
        />
      )}
    </div>
  );
};

export default FormComponent;
