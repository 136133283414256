import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import VideoKYC from "./components/VideoKYC";
import UserConsentModal from "./components/UserConsentModal";
import VerificationType from "./components/VerificationType";
import IdentityVerification from "./components/IdentityVerification";

import LoginPage from "./components/BankUser/LoginPage";
import Dashboard from "./components/BankUser/Dashboard";
import LeadGeneration from "./components/BankUser/LeadGeneration";
import PersonalDetailsForm from "./components/BankUser/PersonalDetailsForm";
import VideoKYCPage from "./components/BankUser/VideoKYCPage";
import InitiateKYCPage from "./components/BankUser/InitiateKYCPage";
import ResumeKYCPage from "./components/BankUser/ResumeKYCPage";
import ReInitiateKYCPage from "./components/BankUser/ReInitiateKYCPage";
import VideoMeeting from "./components/BankUser/VideoMeeting";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/video-kyc" element={<VideoKYC />} />
        <Route path="/user-consent" element={<UserConsentModal />} />
        <Route path="/verification-type" element={<VerificationType />} />
        <Route path="/identity-verification" element={<IdentityVerification />} />

        {/* <Route path="/bank-login" element={<LoginPage />} /> */}
        <Route path="/bank-user-dashboard" element={<Dashboard />} />
        <Route path="/lead-generation" element={<LeadGeneration />} />
        <Route path="/personal-details" element={<PersonalDetailsForm />} />
        <Route path="/proceed" element={<VideoKYCPage />} />
        <Route path="/initiate-kyc" element={<InitiateKYCPage />} />
        <Route path="/resume-kyc" element={<ResumeKYCPage />} />
        <Route path="/re-initiate-kyc" element={<ReInitiateKYCPage />} />
        <Route path="/video-meet" element={<VideoMeeting />} />
      </Routes>
    </Router>
  );
}

export default App;
